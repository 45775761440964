.table-responsive {
  height: 400px; /* Adjust the height as needed */
  overflow-y: auto;
}

.table-responsive thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #343a40; /* Ensures the background color is maintained */
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1; /* Adjust the hover effect as needed */
}

.table-bordered tbody tr {
  border-bottom: 1px solid #dee2e6; /* Adds horizontal borders */
}
