.kplcMainDiv {
  height: 84dvh;
  width: 100%;
}

.kplcWindow {
  height: 100%;
  width: 100%;
}

.accountNo {
  color: #69c37b;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;
}
