/* NotificationsTable.css */
.table-container {
  overflow-y: auto;
  position: relative;
}

.table {
  border-spacing: 0;
}

.table th,
.table td {
  vertical-align: middle;
}

.table thead th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  font-weight: bold;
}

.table thead {
  border-bottom: 2px solid black;
}
