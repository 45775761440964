.notify-page {
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
}

.confirmation-modal {
  text-align: center;
}

.notifications {
  margin-top: 20px;
}

/* Additional styles can be added as per your design preferences */
